<template>
  <b-sidebar
    id="add-new-template-sidebar"
    :visible="isAddNewTemplateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblanktemplate).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("templateAdd") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblanktemplate
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group :label="$t('name')" label-for="name">
              <b-form-input
                id="name"
                v-model="blankTemplateData.templatename"
                autofocus
                :state="
                  formValidation(resetblanktemplate).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTemplateSidebarActive",
    event: "update:is-add-new-template-sidebar-active",
  },
  props: {
    isAddNewTemplateSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankTemplateData: {},
      formValidation: formValidation,
    };
  },

  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-template-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch("formulasModule/addTemplate", this.blankTemplateData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-template-sidebar-active", false);
        });
    },
    async resetblanktemplate() {
      blankTemplateData = {};
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-template-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
