import { render, staticRenderFns } from "./TemplateList.vue?vue&type=template&id=3a81b34b&scoped=true"
import script from "./TemplateList.vue?vue&type=script&lang=js"
export * from "./TemplateList.vue?vue&type=script&lang=js"
import style0 from "./TemplateList.vue?vue&type=style&index=0&id=3a81b34b&prod&lang=scss&scoped=true"
import style1 from "./TemplateList.vue?vue&type=style&index=1&id=3a81b34b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a81b34b",
  null
  
)

export default component.exports