var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formula.hammaddeler == undefined)?_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t("loading")))]),_c('b-spinner',{staticClass:"ml-auto"})],1):_vm._e(),(_vm.formula.hammaddeler != undefined)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div'),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalingredients",modifiers:{"modalingredients":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getFormulaIngredients}},[_vm._v(" "+_vm._s(_vm.$t("ingredientAdd"))+" ")])],1),_c('div',[_c('hr'),_c('b-table',{ref:"refIngredienttListTable",staticClass:"editable-table min-w-t",attrs:{"items":_vm.formula.hammaddeler,"striped":"","small":true,"fields":_vm.fields,"responsive":"","primary-key":"ikey","show-empty":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable",on:{"click":function($event){return _vm.selectColumn(data)}}},[_c('b-form-input',{staticClass:"editable",attrs:{"id":data.item.hammadde + 'min',"data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(
                  data.item.min.toString().replace(',', '.')
                ).toFixed(_vm.getUserData().ingredientdecimal),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable",on:{"click":function($event){return _vm.selectColumn(data)}}},[_c('b-form-input',{staticClass:"editable",attrs:{"id":data.item.hammadde + 'max',"data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(
                  data.item.max.toString().replace(',', '.')
                ).toFixed(_vm.getUserData().ingredientdecimal),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); }}})],1)]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.removeItem(data.index)}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"Trash2Icon"}}),_c('small',{staticClass:"d-none d-lg-inline align-middle ml-50 text-dark"},[_vm._v(_vm._s(_vm.$t("delete")))])],1)])]}}],null,false,1737008649)})],1),_c('b-modal',{attrs:{"id":"modalingredients","scrollable":"","title":_vm.$t('ingredients'),"cancel-title":"Close","ok-title":_vm.$t('ok'),"ok-only":""},on:{"ok":_vm.submitIngredient}},[_c('b-form-group',{attrs:{"label":_vm.$t('search')}},[_c('b-form-input',{on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-table',{attrs:{"items":_vm.allIngredientsSearch,"fields":[
          {
            key: 'selected',
            label: '',
          },
          {
            key: 'ingredientname',
            label: _vm.$t('ingredientName'),
          } ]},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{key:item.id + '-' + item.selected,attrs:{"checked":item.selected == 1},nativeOn:{"change":function($event){return _vm.changeSelected(item)}}})],1)]}}],null,false,1552776455)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }