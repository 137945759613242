<template>
  <div>
    <div
      v-if="formula.besinmaddeleri == undefined"
      class="d-flex align-items-center"
    >
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="formula.besinmaddeleri != undefined" class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div></div>
        <b-button
          v-b-modal.modalnutrients
          class="mb-2 align-self-end"
          variant="outline-primary"
          @click="getFormulaNutrients"
        >
          {{ $t("nutrientAdd") }}
        </b-button>
      </div>
      <div>
        <hr />
        <b-table
          ref="refNutrienttListTable"
          :items="formula.besinmaddeleri"
          striped
          :small="true"
          :fields="fields"
          responsive
          class="editable-table min-w-t"
          primary-key="ikey"
          show-empty
          :empty-text="$t('noRecords')"
        >
          <template #cell(min)="data">
            <div class="editable" @click="selectColumn(data)">
              <b-form-input
                :id="data.item.hammadde + 'min'"
                class="editable"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(
                    data.item.min.toString().replace(',', '.')
                  ).toFixed(getUserData().nutrientdecimal)
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.min = parseFloat(v))"
              />
            </div>
          </template>
          <template #cell(max)="data">
            <div class="editable" @click="selectColumn(data)">
              <b-form-input
                :id="data.item.hammadde + 'max'"
                class="editable"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(
                    data.item.max.toString().replace(',', '.')
                  ).toFixed(getUserData().nutrientdecimal)
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.max = parseFloat(v))"
              />
            </div>
          </template>

          <template #cell(delete)="data">
            <b-link @click="removeItem(data.index)">
              <div class="d-flex">
                <feather-icon class="text-danger" icon="Trash2Icon" />
                <small
                  class="d-none d-lg-inline align-middle ml-50 text-dark"
                  >{{ $t("delete") }}</small
                >
              </div>
            </b-link>
          </template>
        </b-table>
      </div>
      <b-modal
        id="modalnutrients"
        scrollable
        :title="$t('nutrients')"
        :ok-title="$t('ok')"
        ok-only
        @ok="submitNutrient"
      >
        <b-form-group :label="$t('search')">
          <b-form-input v-model="search" @keyup="changeItem()" />
        </b-form-group>
        <b-table
          :items="allNutrientsSearch"
          :fields="[
            {
              key: 'selected',
              label: '',
            },
            {
              key: 'nutrientname',
              label: $t('nutrientName'),
            },
          ]"
        >
          <template #cell(selected)="{ item }">
            <div class="d-flex">
              <b-form-checkbox
                :key="item.id + '-' + item.selected"
                :checked="item.selected == 1"
                @change.native="changeSelected(item)"
              ></b-form-checkbox>
            </div>
          </template>
        </b-table>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCardText,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BLink,
  BTable,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BLink,
    BTable,
  },
  props: {
    formula: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      getUserData: getUserData,
      search: "",
      allNutrientsSearch: [],
      editColumn: { cell: "", index: "" },
      fields: [
        {
          key: "nutrientname",
          label: this.$t("nutrientName"),
          tdClass: "name1-col",
        },
        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          tdClass: "min1-col",
        },

        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          tdClass: "max1-col",
          type: "number",
        },

        { key: "delete", label: this.$t("delete"), tdClass: "remove-col" },
      ],
    };
  },
  computed: {
    allNutrients() {
      return this.$store.state.formulasModule.allNutrients;
    },
    nutrientLoading() {
      return this.$store.state.formulasModule.nutrientLoading;
    },
  },
  async mounted() {
    const editebleColumns = this.fields
      .filter((x) => x.editable)
      .map((x) => x.key);
    var keys = [];

    document.addEventListener("keyup", (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter" || e.code === "Tab") {
        if (this.editColumn.cell != "") {
          var cellIndex = editebleColumns.indexOf(this.editColumn.cell) + 1;
          this.editColumn.cell =
            editebleColumns[cellIndex % editebleColumns.length];
          if (this.editColumn.cell === editebleColumns[0] && keys.length != 2) {
            this.editColumn.index = this.editColumn.index + 1;
          }
        }
      }
      this.focusSelectedInput(this.editColumn.cell, this.editColumn.index);
    });
  },

  methods: {
    toFixed(num, fixed) {
      var re = new RegExp("^-?\\d+(?:\.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
    selectedColumn(data) {
      return (
        this.editColumn.cell == data.field.key &&
        this.editColumn.index == data.index
      );
    },
    selectColumn(data) {
      this.editColumn.cell = data.field.key;
      this.editColumn.index = data.index;
      this.focusSelectedInput(data.field.key, data.index);
    },
    focusSelectedInput(key, index) {
      var input = document.querySelector(
        `input[data-id="nutrient-${key + index}"]`
      );
      if (input)
        window.setTimeout(() => {
          input.focus();
        }, 1);
    },
    outside(e) {
      if (
        !(
          e.target.classList.contains("editable") ||
          e.target.parentElement.classList.contains("editable") ||
          e.target.childElement?.classList?.contains("editable")
        )
      ) {
        this.editColumn = { cell: "", index: "" };
      }
    },

    removeItem(index) {
      this.formula.besinmaddeleri.splice(index, 1);
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeActive(item) {
      item.aktif == 1 ? (item.aktif = 0) : (item.aktif = 1);
    },
    changeItem() {
      this.allNutrientsSearch = this.allNutrients.filter((x) => {
        return x.nutrientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
    async getFormulaNutrients() {
      await this.$store.dispatch(
        "formulasModule/getFormulaNutrients",
        this.formula.besinmaddeleri.map((e) => e.nkey)
      );
      this.allNutrientsSearch = this.allNutrients;
    },
    async submitNutrient() {
      this.formula.besinmaddeleri = await this.$store.dispatch(
        "formulasModule/updateNutrients",
        this.formula.besinmaddeleri
      );
      this.search = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}

.editable-table .data-cell {
  vertical-align: middle;
}

.editable {
  max-width: 95%;
}

.name-col {
  width: 200px;
}

.kod-col {
  width: 50px;
}
.min1-col {
  width: 80px;
  border: 1px solid #e9ecef;
}
.min1-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min1-col:hover {
  cursor: pointer;
}
.max1-col {
  width: 80px;
  border: 1px solid #e9ecef;
}
.max1-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max1-col .max1-col:hover {
  cursor: pointer;
}

.name1-col {
  width: 10px;
}

.remove-col {
  width: 20px;
}

.is-active-col {
  width: 100px;
}
.editable > input {
  max-width: 95%;
}
.min-w-t > table {
  width: 100%;
  border-collapse: collapse;
}
.min-w-t > table.b-table {
  width: inherit !important;
}
.min-w-t > table {
  min-width: 300px !important;
}
</style>
