import { render, staticRenderFns } from "./TemplateNutrient.vue?vue&type=template&id=68d00671"
import script from "./TemplateNutrient.vue?vue&type=script&lang=js"
export * from "./TemplateNutrient.vue?vue&type=script&lang=js"
import style0 from "./TemplateNutrient.vue?vue&type=style&index=0&id=68d00671&prod&lang=scss"
import style1 from "./TemplateNutrient.vue?vue&type=style&index=1&id=68d00671&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports