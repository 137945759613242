import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import dayjs from "dayjs";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import { BriefcaseIcon } from "vue-feather-icons";

export default function useTemplatesList() {
  // Use toast
  const toast = useToast();

  const refTemplateListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "templateName", label: VueI18n.t("templateName"), sortable: false },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalTemplates = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refTemplateListTable.value
      ? refTemplateListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    };
  });

  const refetchData = () => {
    refTemplateListTable.value.refresh();
  };

  watch([currentPage, searchQuery, perPage, statusFilter], () => {
    refetchData();
  });

  const templates = ref([]);
  let allTemplates = [];
  const fetchTemplates = async (ctx, callback) => {
    if (allTemplates.length === 0) {
      allTemplates = await store
        .dispatch("formulasModule/fetchTemplates")
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              text: VueI18n.t("xFetchError", { value: VueI18n.t("templates") }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      templates.value = allTemplates;
    } else if (searchQuery.value != null && templates.value.length != 0) {
      templates.value = allTemplates.filter((x) =>
        x.TemplateName.toString()
          .toLowerCase()
          .includes(searchQuery.value.toString().toLowerCase())
      );
    }
    return templates.value;
  };

  const resetList = () => {
    templates.value = [];
    allTemplates = [];
  };

  const deleteTemplate = async (ctx) => {
    await store.dispatch("formulasModule/deleteTemplate", ctx.id);
    resetList();
    refetchData();
  };
  const updateTemplate = async (template) => {
    template = {
      ...template,
      hammaddeler: template.hammaddeler.map((x) => ({
        id: x.id,
        templateid: template.id,
        ikey: x.ikey,
        min: x.min,
        max: x.max,
        hammadde: x.ingredientname,
      })),
      besinmaddeleri: template.besinmaddeleri.map((x) => ({
        id: x.id,
        templateid: template.id,
        nkey: x.nkey,
        min: x.min,
        max: x.max,
        besinMaddesi: x.nutrientname,
      })),
    };
    var isOk = await store
      .dispatch("formulasModule/updateTemplate", template)
      .then((isOk) => {
        refetchData();
        if (isOk)
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: VueI18n.t("updated", { type: VueI18n.t("ingredient") }),
            },
          });
        else
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("successful"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: VueI18n.t("updated", { type: VueI18n.t("ingredient") }),
            },
          });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTemplateListTable,
    deleteTemplate,
    statusFilter,
    resetList,
    refetchData,
    updateTemplate,
  };
}
